$(document).on("change", "input[name='is_hide_forecast_graph']", function () {
  let checkbox_status = $(this)[0]['checked'];
  let power_plant_id = $(this).data()['id'];

  $.ajax({
    type: 'POST',
    url: `/settings/power_plants/${power_plant_id}/update_is_hide_forecast_graph`,
    data: {is_checked: checkbox_status},
    dataType: 'json'
  })

});